import * as React from "react";

import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";

import * as styles from "./betaFeedback.module.css";
import * as mainStyles from "./index.module.css";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
} from "@mui/material";

const renderRadio = function () {
  const radios = [];
  for (let index = 0; index < 10; index++) {
    radios.push(
      <FormControlLabel
        value={index + 1}
        control={<Radio />}
        label={index + 1}
      />
    );
  }

  return radios;
};

// markup
const IndexPage = ({ data }) => {
  console.log(data);
  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nuff App feedback form</title>
      </Helmet>
      <Grid container columnSpacing="20px" className={styles.container}>
        <Grid item xs={12} alignItems="center" justifySelf="center">
          <div className={mainStyles.content}>
            <span className={mainStyles.header}>
              <h1>Nuff</h1>
            </span>
            <form data-netlify="true" name="Beta feedback" method="POST">
              <input type="hidden" name="form-name" value="Beta feedback" />
              <section>
                <FormControl className={styles.formControl}>
                  <h3>Introduction</h3>
                  <p>
                    We are Nuff. Three friends with a passion for food and
                    eating out. We hope to share that passion, along with the
                    experiences and tricks we've learned over the years, with
                    our app.
                  </p>
                  <p>
                    As our beta-testers, please could you take the time to fill
                    out the following feedback form? Please remember we'd value
                    your total honesty more than anything else! Thank you for
                    your time.{" "}
                  </p>
                  <FormLabel>Full Name?</FormLabel>
                  <TextField
                    name="what is your name"
                    label="Outlined"
                    variant="outlined"
                  />
                </FormControl>
              </section>
              <section>
                <h3>The Benefits of Nuff</h3>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    On a scale of 1-10, how useful did you find Nuff?
                  </FormLabel>
                  <RadioGroup
                    name="On a scale of 1-10, how useful did you find Nuff?"
                    row
                  >
                    {renderRadio()}
                  </RadioGroup>
                </FormControl>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    What would you describe as your main reason for using Nuff?
                  </FormLabel>
                  <FormControlLabel
                    name="What would you describe as your main reason for using Nuff?"
                    control={<Checkbox />}
                    label="Finding new places"
                    value="Finding new places"
                  />
                  <FormControlLabel
                    name="What would you describe as your main reason for using Nuff?"
                    control={<Checkbox />}
                    label="Finding somewhere specific to your current location"
                    value="Finding somewhere specific to your current location"
                  />
                  <FormControlLabel
                    name="What would you describe as your main reason for using Nuff?"
                    control={<Checkbox />}
                    label="Other"
                    value="Other"
                  />
                  <TextField
                    name="What would you describe as your main reason for using Nuff?"
                    label="If other"
                    variant="outlined"
                  />
                </FormControl>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    On a scale of 1-10, how likely are you to use Nuff again?
                  </FormLabel>
                  <RadioGroup
                    name="On a scale of 1-10, how likely are you to use Nuff again?"
                    row
                  >
                    {renderRadio()}
                  </RadioGroup>
                </FormControl>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    Is Nuff something you'd recommend to a friend?
                  </FormLabel>
                  <RadioGroup row>
                    <FormControlLabel
                      name="Is Nuff something you'd recommend to a friend?"
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      name="Is Nuff something you'd recommend to a friend?"
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </section>

              <section>
                <h3>Content & Appearance</h3>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    On a scale of 1-10, how do you rate the quality of content
                    on Nuff?
                  </FormLabel>
                  <RadioGroup
                    name="On a scale of 1-10, how do you rate the quality of content
                    on Nuff?"
                    row
                  >
                    {renderRadio()}
                  </RadioGroup>
                </FormControl>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    On a scale of 1-10, how useful is the descriptive content of
                    the restaurants on Nuff?
                  </FormLabel>
                  <RadioGroup
                    name="On a scale of 1-10, how useful is the descriptive content of
                    the restaurants on Nuff?"
                    row
                  >
                    {renderRadio()}
                  </RadioGroup>
                </FormControl>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    On a scale of 1-10, how do you rate the tone of the content
                    on Nuff?
                  </FormLabel>
                  <RadioGroup
                    name="On a scale of 1-10, how do you rate the tone of the content
                    on Nuff?"
                    row
                  >
                    {renderRadio()}
                  </RadioGroup>
                </FormControl>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    On a scale of 1-10, how do you rate the appearance and
                    design aspects of Nuff?
                  </FormLabel>
                  <RadioGroup
                    name="On a scale of 1-10, how do you rate the appearance and
                    design aspects of Nuff?"
                    row
                  >
                    {renderRadio()}
                  </RadioGroup>
                </FormControl>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    Is there anything you'd like to add regarding the content
                    and appearance of Nuff?
                  </FormLabel>
                  <TextareaAutosize
                    name="Is there anything you'd like to add regarding the content
                  and appearance of Nuff?"
                    className={styles.textArea}
                    id="content-appearance-feedback"
                  />
                </FormControl>
              </section>
              <section>
                <h3>Functionality</h3>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    On a scale of 1-10, how do you rate the functionality of the
                    app?
                  </FormLabel>
                  <RadioGroup
                    name="On a scale of 1-10, how do you rate the functionality of the
                    app?"
                    row
                  >
                    {renderRadio()}
                  </RadioGroup>
                </FormControl>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    On a scale of 1-10, how do you rate the tools on the app?
                  </FormLabel>
                  <RadioGroup
                    name="On a scale of 1-10, how do you rate the tools on the app?"
                    row
                  >
                    {renderRadio()}
                  </RadioGroup>
                </FormControl>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    Do you think you'd use the list view or the map view more?
                  </FormLabel>
                  <RadioGroup row>
                    <FormControlLabel
                      name="Do you think you'd use the list view or the map view more?"
                      value="list"
                      control={<Radio />}
                      label="List View"
                    />
                    <FormControlLabel
                      name="Do you think you'd use the list view or the map view more?"
                      value="map"
                      control={<Radio />}
                      label="Map View"
                    />
                  </RadioGroup>
                </FormControl>
              </section>
              <section>
                <h3>The future of Nuff and your opinions</h3>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    What would make you keep coming back to Nuff?
                  </FormLabel>
                  <TextareaAutosize
                    name="What would make you keep coming back to Nuff?"
                    className={styles.textArea}
                  />
                </FormControl>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    Are there any improvements you'd like to see to the app in
                    it's current guise?
                  </FormLabel>
                  <TextareaAutosize
                    name="Are there any improvements you'd like to see to the app in
                    it's current guise?"
                    className={styles.textArea}
                  />
                </FormControl>
                <FormControl className={styles.formControl}>
                  <FormLabel>
                    What else would you like to see included in Nuff?
                  </FormLabel>
                  <TextareaAutosize
                    name="What else would you like to see included in Nuff?"
                    className={styles.textArea}
                  />
                </FormControl>
              </section>
              <Button variant="outlined" type="submit">
                Submit feedback
              </Button>
            </form>
            <p>
              Thank you for your time and feedback. It's greatly appreciated.
            </p>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IndexPage;
